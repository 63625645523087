<template>
  <div>
    <MasterHeader />
    <div class="container container-wide" v-if="isUserEmployee">
      <div class="row">
        <div class="col-12">
          <h1 class="mb-4">{{ company.companyName }}</h1>
        </div>
        <div class="col-12 col-md-6 col-lg-6 pr-2">
          <!-- PROFESSIONAL - OFFERS -->
          <div
            v-if="
              user.role === 'Professional' &&
                user.company.length > 0 &&
                companyOffers.length > 0
            "
          >
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4>Negotiations</h4>
              </div>
              <div class="widget-body">
                <router-link
                  :to="'/collaborations/' + offer.offer.collaborationId"
                  v-for="(offer, index) in companyOffers"
                  :key="index"
                  class="erow row link d-flex"
                >
                  <div class="col-12 col-lg-1 align-self-center">
                    <i class="fas fa-hands-helping"></i>
                  </div>
                  <div class="col-12 col-lg-5 align-self-center">
                    Title
                    <div class="headline">
                      {{ offer.offer.collaborationTitle }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    Status
                    <div class="headline">
                      <span v-if="offer.offer.status === 1"
                        >Waiting for interview accept from students</span
                      >
                      <span v-if="offer.offer.status === 2"
                        >The students needs to confirm the collaboration after
                        your interview</span
                      >
                      <span v-if="offer.offer.status === 3"
                        >You need to confirm the collaboration</span
                      >
                      <span v-if="offer.offer.status === 4">Collaborating</span>
                    </div>
                  </div>
                  <div class="col-12 col-lg-2 align-self-center text-right">
                    <span
                      class="btn btn-primary"
                      v-if="offer.offer.status === 3"
                      >View to confirm</span
                    >
                    <span class="btn btn-link color-third" v-else>View</span>
                  </div>
                </router-link>
              </div>
            </div>
          </div>

          <div class="widget mb-2">
            <div class="widget-splash splash-line"></div>
            <div class="widget-header">
              <h4>Users</h4>
              <div class="widget-navigation"></div>
            </div>
            <div class="widget-body">
              <template v-if="companyEmployees.length > 0">
                <div
                  class="erow row d-flex"
                  v-for="(employee, index) in companyEmployees"
                  :key="index"
                >
                  <div class="col-12 col-lg-1 align-self-center">
                    <div class="align-self-center">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    <div>
                      Full name
                      <small
                        class="color-first"
                        v-if="user.userId === employee.id"
                        >( you )</small
                      >
                    </div>
                    <div class="headline">
                      {{ employee.user.firstName }} {{ employee.user.lastName }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-3 align-self-center">
                    <div>Email</div>
                    <div class="headline">{{ employee.user.email }}</div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center text-right">
                    <span
                      class="btn btn-link btn-sm color-third ml-3"
                      @click="removeEmployee(employee)"
                      v-if="
                        companyAdmins.length > 1 &&
                          isUserAdmin &&
                          user.userId === employee.id
                      "
                      title="Remove employee"
                      >Leave</span
                    >
                    <span
                      class="btn btn-link btn-sm color-third ml-3"
                      @click="removeEmployee(employee)"
                      v-if="
                        companyAdmins.length > 1 &&
                          isUserAdmin &&
                          user.userId !== employee.id
                      "
                      title="Remove employee"
                      >Remove</span
                    >
                    <span
                      class="btn btn-link btn-sm color-third ml-3"
                      @click="removeEmployee(employee)"
                      v-if="
                        user.userId !== employee.id &&
                          !company.companyAdmins.includes(employee.id) &&
                          isUserAdmin
                      "
                      title="Remove employee"
                      >Remove</span
                    >
                    <span
                      class="btn btn-link btn-sm color-third ml-3"
                      @click="removeEmployee(employee)"
                      v-if="user.userId === employee.id && !isUserAdmin"
                      title="Leave the company"
                      >Leave</span
                    >
                    <span
                      class="btn btn-link btn-sm color-second ml-3"
                      v-if="
                        !company.companyAdmins.includes(employee.id) &&
                          isUserAdmin
                      "
                      @click="promoteToAdmin(employee)"
                      >Promote</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>

          <div class="widget" style="border-top:none">
            <div
              class="widget-header"
              style="border-right:1px solid #e9e9e9;border-top:1px solid #e9e9e9;"
            >
              <h4>Admins</h4>
              <div class="widget-navigation">
                <!-- <router-link :to="'/collaboration/my'" class="widget-nav"><i class="fas fa-link"></i>Go to My Collaborations</router-link> -->
              </div>
            </div>
            <div class="widget-body">
              <template v-if="companyAdmins.length > 0">
                <div
                  class="erow row slim d-flex"
                  v-for="(employee, index) in companyAdmins"
                  :key="index"
                >
                  <div class="col-12 col-lg-1 align-self-center">
                    <div class="align-self-center">
                      <i class="fas fa-user"></i>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    <div>
                      Full name
                      <small
                        class="color-first"
                        v-if="user.userId === employee.id"
                        >( you )</small
                      >
                    </div>
                    <div class="headline">
                      {{ employee.user.firstName }}
                      {{ employee.user.lastName }}
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    <div>Email</div>
                    <div class="headline">{{ employee.user.email }}</div>
                  </div>
                  <div class="col-12 col-lg-3 align-self-center text-right">
                    <span
                      class="btn btn-link btn-sm color-third"
                      v-if="companyAdmins.length > 1"
                      @click="demoteAdmin(employee)"
                      title="Demote user"
                      >Demote</span
                    >
                  </div>
                </div>
              </template>

              <!-- invites -->

              <div v-if="companyInvites.length" class="mt-4">
                <h6>Invites</h6>
                <div
                  class="erow row d-flex"
                  v-for="(invite, index) in companyInvites"
                  :key="index"
                >
                  <div class="col-12 col-lg-1 align-self-center">
                    <div class="align-self-center">
                      <i class="fas fa-paper-plane"></i>
                    </div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    <div>Sender</div>
                    <div class="headline">{{ invite.invites.sender }}</div>
                  </div>
                  <div class="col-12 col-lg-4 align-self-center">
                    <div>To email</div>
                    <div class="headline">{{ invite.invites.targetEmail }}</div>
                  </div>
                  <div class="col-12 col-lg-3 align-self-center text-right">
                    <span
                      @click="declineCompanyInvitation(invite)"
                      class="btn btn-link color-third"
                      title="Cancel invitation"
                      >Cancel invite</span
                    >
                  </div>
                </div>
              </div>

              <div class="mt-4">
                <h6 class="mb-3">Invite a colleague</h6>
                <form @submit.prevent="sendCompanyInvitation()">
                  <div class="row d-flex">
                    <div class="col-12 col-lg-9 mb-2">
                      <input
                        type="email"
                        class="form-control"
                        placeholder="Enter email to invite"
                        v-model="inviteEmail"
                        required
                      />
                      <div class="alert alert-danger mt-3" v-if="inviteError">
                        {{ inviteError }}
                      </div>
                      <div
                        class="alert alert-success mt-3"
                        v-if="inviteMessage"
                      >
                        {{ inviteMessage }}
                      </div>
                    </div>
                    <div class="col-12 col-lg-3">
                      <button class="btn btn-primary btn-block">Invite</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <!-- COLLABORATIONS -->
          <div v-if="isUserEmployee && companyCollaborations.length > 0">
            <div class="widget">
              <div class="widget-header">
                <h4>Collaborations</h4>
              </div>
              <div class="widget-body">
                <div
                  v-for="(offer, index) in companyCollaborations"
                  :key="index"
                  class="erow row d-flex"
                >
                  <div class="col-12 col-lg-1 align-self-center">
                    <i class="fas fa-hands-helping"></i>
                  </div>
                  <div class="col-12 col-lg-6 align-self-center">
                    <div class="headline">
                      {{ offer.offer.collaborationTitle }}
                    </div>
                  </div>

                  <div class="col-12 col-lg-3 align-self-center">
                    Semester
                    <div class="headline">
                      {{ offer.offer.collaborationTargetSemester }}
                    </div>
                    <div class="highlight">
                      {{ offer.offer.collaborationSemester }}
                    </div>
                    <!-- TODO FIX THIS -->
                  </div>
                  <div class="col-12 col-lg-2 align-self-center text-right">
                    <router-link
                      :to="'/collaborations/' + offer.offer.collaborationId"
                      class="btn btn-link color-third"
                      >View</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- <hr>
            <div class="widget">
                <div class="widget-header">
                    <h4>Orders summary ({{orders.length}})</h4>
                    <div class="widget-navigation">
                    </div>
                </div>
                <div class="widget-body">


                  <div class="erow row slim d-flex" v-for="(order, index) in orders" :key="index">
                    <div class="col-1 align-self-center">
                        <div class="align-self-center">
                            <i class="fas fa-user" ></i>
                        </div>
                    </div>

                    <div>
                      <h4>#{{order.id}} - {{order.offerDetails.offer.collaborationTitle}}</h4>
                      <h6>Students</h6>
                      <div v-for="(member,index) in order.members" :key="index">
                        {{ member.members.firstName }} {{ member.members.lastName }} {{ member.members.institution.name }}
                      </div>

                      Accepted by {{ order.offerDetails.offer.user.firstName }}
                      <br><br>
                      Price: {{ order.matchPrice }}

                      <h6></h6>

                      </div>
                  </div>
                     {{ orders }}
 
                </div>
            </div>   -->
        </div>
        <div class="col-12 col-md-6 col-lg-6 pl-2">
          <div class="widget">
            <div class="widget-splash splash-line"></div>
            <div class="widget-header">
              <h4>Public Details</h4>
              <div class="widget-navigation">
                <router-link
                  :to="'/company/edit/' + this.$route.params.id"
                  class="widget-nav"
                  v-if="isUserAdmin"
                  ><i class="fas fa-user-edit"></i>Edit Company</router-link
                >
              </div>
            </div>
            <div class="widget-body">
              <div class="company-details">
                <div v-if="company.imageUrl" class="image-container mt-2 mb-3">
                  <img :src="company.imageUrl" style="width:250px" />
                </div>

                <div v-if="company.industry" class="company-industry-section">
                  <h4 class="header-margin">{{ company.companyName }}</h4>
                  <p class="mb-0">{{ company.industry.name }}</p>
                </div>
                <div
                  v-if="company.companyDescription"
                  class="company-description"
                >
                  <h6 class="header-margin">
                    Description
                  </h6>
                  <p class="mb-0">
                    {{ company.companyDescription }}
                  </p>
                </div>

                <div v-if="company.companyURL" class="" style="color: #0a7380">
                  <p class="header-margin">{{ company.companyURL }}</p>

                  <p class="mb-0" v-if="company.companyLinkedinUrl">
                    {{ company.companyLinkedinUrl }}
                  </p>
                </div>

                <p class="header-margin">
                  <span class="text-capitalize"
                    >{{ company.billingStreetAddress }},</span
                  >
                  <span class="text-capitalize">
                    {{ company.billingPostCode }}
                    {{ company.billingCity }},</span
                  >
                  <span class="text-capitalize">
                    {{ company.billingCountry }}</span
                  >
                </p>
                <div v-if="!user.subscription" class="header-margin">
                  <router-link to="/subscription/" class="btn btn-secondary"
                    >Select Subscription Plan
                  </router-link>
                </div>
                <div v-if="user.subscription" class="subscription-details">
                  <h4 class="subscription header-margin">Subscription :</h4>
                  <h4 class="subscription-plan mt-2">
                    {{ user.subscriptionPlan }} Program
                  </h4>
                  <router-link to="/subscription/" class="btn btn-secondary"
                    >Edit Subscription Plan
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 " v-if= "user.subscription && company.subscriptionId">
            <div class="widget">
              <div class="widget-splash splash-line"></div>
              <div class="widget-header">
                <h4>Thesis slots overview</h4>
              </div>
            <div class="widget-body">
              <div class="erow row link d-flex">
              <div class="col-12 col-lg-4 align-self-center">
                <div>
                  This overview shows your allocation of thesis slots
                </div>
              </div>
              <div class="col-12 col-lg-4 align-self-center">
                <div v-if="slotsUsed">
                  <p v-for="slot in slotsUsed.slice(1)" :key="slot[0]">{{slot[0]}} : {{slot[1]}} out of {{slotsUsed[0]}} used</p>
                  <!-- {{slotsUsed}} -->
                  <!-- Spring 2021 : 2 out of 3 
                  <br>
                  Fall 2021 : 1 out of 3 -->
                </div>
              </div>
              <!-- <th v-for="ts in thesisSlots.slice(1)" :key="ts[0]" scope="col">{{ts[0]}}</th> -->
              <div class="col-12 col-lg-4 align-self-center text-right">
                <router-link
                  :to="'/company-tokens/'"
                  class="btn btn-link color-third"
                  >View</router-link
                >
              </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div v-else class="container">
      <!-- <h1>Oops! - Something broke</h1> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "../../firebase";
import moment from "moment";
import MasterHeader from "@/components/MasterHeader.vue";

export default {
  data() {
    return {
      inviteEmail: "",
      inviteError: "",
      inviteMessage: "",
      company: {
        created: "",
        lastEdited: "",
      },
    };
  },
  components: {
    MasterHeader,
  },
  created() {
    this.$store.dispatch("getCompanyCollaborationsById", this.$route.params.id);
  },

  firestore() {
    return {
      company: db.collection("companies").doc(this.$route.params.id),
      // orders: db
      //   .collection("collaborationOrders")
      //   .where("offerDetails.offer.companyId", "==", this.$route.params.id),
    };
  },
  computed: {
    ...mapState(["user"]),

    isUserEmployee: function() {
      var result = false;
      var employees = this.company.companyEmployees;
      if (typeof employees !== typeof undefined) {
        this.$store.dispatch("getCompanyEmployees", employees);
        this.$store.dispatch(
          "getCompanyInvitesByCompanyId",
          this.$route.params.id
        );
        this.$store.dispatch('getCompanyDetails', this.$route.params.id);
        this.$store.dispatch("getCompanyOffersById", this.user);

        //getCompanyInvitesByCompanyId
        result = employees.includes(this.user.userId);
      }
      return result;
    },

    isUserAdmin: function() {
      var result = false;
      var admins = this.company.companyAdmins;
      if (typeof admins !== typeof undefined) {
        result = admins.includes(this.user.userId);
        this.$store.dispatch("getCompanyAdmins", admins);
      }
      return result;
    },

    companyEmployees: function() {
      var result = this.$store.state.company.companyEmployees;
      return result;
    },
    
    companyAdmins: function() {
      var result = this.$store.state.company.companyAdmins;
      return result;
    },
    companyInvites: function() {
      return this.$store.state.company.companyInvites;
    },
    companyCollaborations: function() {
      return this.$store.state.company.companyCollaborations;
    },
    companyOffers: function() {
      return this.$store.state.company.companyOffers;
    },
    subscriptionId: function(){
      return this.$store.state.company.companyDetails.subscriptionId;
    },
    slotsUsed: function(){
      let slots = this.$store.getters.thesisSlotsUsed
      // slots.slice(1).forEach(elem=>{})
      return slots
    }
  },
  watch: {
    subscriptionId(value){
      this.tokenSlotsUsed()
    }
  },
  // mounted: {
  //   subscriptionId() {
  //     this.tokenSlotsUsed()
  //   }
  // },
  methods: {
    sendCompanyInvitation() {
      var vm = this;
      var pendingCompanyInvites = this.$store.state.company.companyInvites;
      var existingEmployees = this.$store.state.company.companyEmployees;
      var isAlreadyInvited = false;

      this.inviteError = "";
      this.inviteMessage = "";

      pendingCompanyInvites.forEach(function(member, index) {
        if (member.invites.targetEmail === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      existingEmployees.forEach(function(member, index) {
        if (member.user.email === vm.inviteEmail) {
          isAlreadyInvited = true;
        }
      });

      if (isAlreadyInvited) {
        this.inviteError = "The user is already a colleague or invited";
      } else {
        var data = {
          companyId: this.$route.params.id,
          companyName: this.company.companyName,
          sender: this.user.firstName + " " + this.user.lastName,
          senderEmail: this.user.email,
          targetEmail: this.inviteEmail,
        };

        db.collection("users")
          .where("email", "==", vm.inviteEmail)
          .get()
          .then(function(querySnapshot) {
            var collection = [];
            querySnapshot.forEach((doc) => {
              collection.push({ id: doc.id, user: doc.data() });
            });

            if (collection.length > 0) {
              //if user already exists
              if (collection[0].user.role === "Professional") {
                //MAIL 'Professional - Invitation Recieved, already a user'
                //firstName (this user), lastName (this user), param1: receiver.firstName, param2: company.name ,param3: company.id
                const templateData = {
                  toEmail: vm.inviteEmail,
                  firstName: collection[0].user.firstName, //vm.user.firstName,
                  param1: vm.user.firstName,
                  param2: vm.user.lastName,
                  param3: vm.company.companyName,
                  template:
                    "Professional - Invitation Recieved, already a user",
                };
                vm.$store.dispatch("sendMail", templateData);
                vm.inviteMessage = "Invite send.";
              } else {
                vm.inviteError =
                  "The user you are inviting has a student role."; //not sending or dispatching anything
              }
            } else {
              // user does not exist
              //MAIL 'Professional - Invitation Recieved, Not a user'
              //firstName (this user), lastName (this user), param1: company.name
              const templateData = {
                toEmail: vm.inviteEmail,
                firstName: vm.user.firstName,
                lastName: vm.user.lastName,
                param1: vm.company.companyName,
                template: "Professional - Invitation Recieved, Not a user",
              };
              vm.$store.dispatch("sendMail", templateData);
              vm.inviteMessage = "Invite send.";
            }
            vm.inviteEmail = ""; //clear email in form
          });

        this.$store.dispatch("inviteUserToCompany", data);
        this.$store.dispatch(
          "getCompanyEmployees",
          this.company.companyEmployees
        );
      }
    },

    declineCompanyInvitation(payload) {
      this.$store.dispatch("deleteCompanyInviteById", payload);
    },
    removeEmployee(payload) {
      var vm = this;
      const data = {
        employee: payload,
        companyId: this.$route.params.id,
      };
      //if user is not the event actor.. send mail
      if (payload.id !== this.user.userId) {
        //MAIL 'Professional - Remove team member' @firstName, param1: company.name
        const templateData = {
          toEmail: payload.user.email,
          firstName: payload.user.firstName,
          param1: vm.company.companyName,
          template: "Professional - Remove team member",
        };
        vm.$store.dispatch("sendMail", templateData);
      }
      this.$store.dispatch("removeCompanyEmployeeByUserId", data);
      if (payload.id === this.user.userId) {
        vm.$router.push("/dashboard/");
      }
    },
    demoteAdmin(payload) {
      var vm = this;
      const data = {
        admin: payload.id,
        companyId: this.$route.params.id,
      };

      if (payload.id !== this.user.userId) {
        //MAIL 'Professional - Demote to user' @firstName (this user), lastName (this user), param1: company.name
        const templateData = {
          toEmail: payload.user.email,
          firstName: payload.user.firstName,
          param1: vm.company.companyName,
          template: "Professional - Demote to user",
        };
        vm.$store.dispatch("sendMail", templateData);
      }
      this.$store.dispatch("demoteCompanyAdminByUserId", data);
    },

    promoteToAdmin(payload) {
      var vm = this;
      const data = {
        admin: payload.id,
        companyId: this.$route.params.id,
      };

      if (payload.id !== this.user.userId) {
        //MAIL 'Professional - Promote to admin' @firstName, lastName (this user), param1: company.name
        const templateData = {
          toEmail: payload.user.email,
          firstName: payload.user.firstName,
          param1: vm.company.companyName,
          param2: vm.$route.params.id,
          template: "Professional - Promote to admin",
        };
        vm.$store.dispatch("sendMail", templateData);
      }

      this.$store.dispatch("promoteCompanyAdminByUserId", data);
    },
    moment: function(seconds) {
      return moment.unix(seconds);
    },
    cutText: function(text, characters) {
      return text.length > characters
        ? text.substring(0, characters) + "..."
        : text;
    },
  tokenSlotsUsed(){
    this.$store.dispatch("getThesisSlotsUsed", {subscription: this.subscriptionId, company: this.$route.params.id})
  },
  },
};
</script>

<style scoped>
.image-container {
  width: 300px;
}
.img {
  width: 100%;
}

.company-industry-section h4 {
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
}
.company-industry-section p {
  font-size: 12px;
  font-weight: 400;
}
.header-margin {
  margin: 12px 0 3px 0;
}
.company-description h6 {
  font-size: 14px;
  font-weight: 700;
}
.company-description p {
  font-size: 14px;
  font-weight: 400;
}
.company-details {
  border-radius: 4px;
  border: 1px solid #e9e9e9;
  background: #fff;
  margin: 0 0 10px 0;
  padding: 12px 16px;
  font-size: 13px;
  text-decoration: none;
}

.subscription {
  font-weight: 500;
  color: #5A2871;
  font-size: 14px;
}
.subscription-plan {
  color: #828282;
  font-size: 14px;
  font-weight: 400;
}
</style>
